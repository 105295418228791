.nav-logo{
    max-width: 180px;
    margin-left: 0px !important;
}

nav a {
    color: #777;
    text-decoration: none;
    padding-bottom: 6px;
    margin: 10px 0px 0px 20px;
}

nav a.active {
    color: #555;
    border-bottom: 3px solid #2CB8DF;
}

nav a:hover {
    color: #555;
}