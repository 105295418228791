@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

body {
  font-family: "Mulish", sans-serif;
  font-size: 100%;
}

.vh-fill {
  min-height: calc(100vh - 140px);
}

p {
  font-size: 1.375em;
  font-weight: 400;
}

/* Typography */

.fs-60 {
  font-size: 3.75em;
  font-weight: 700;
}

.fs-48 {
  font-size: 3em;
  font-weight: 700;
}

.fs-36 {
  font-size: 2.25em;
  font-weight: 700;
}

.fs-26 {
  font-size: 1.625em;
  font-weight: 600;
}

.fs-20 {
  font-size: 1.25em;
}

.fs-16 {
  font-size: 1em;
}

/* Buttons */

.bps-primary-button {
  background-color: #1c304c;
  border: none;
  border-radius: 0%;
  font-size: 18px;
}

.bps-primary-button:hover, .bps-primary-button:active, .bps-primary-button:focus {
  background-color: #4787C2;
}

/* Colors */

.bps-bg-light {
  background-color: #FAF5F0;
}

.bps-bg-midnight {
  background-color: #1c304c;
}

.bps-midnight-tint {
  color: #65687D;
}

.bps-midnight {
  color: #1C304C;
}

.bps-sky {
  color: #4787C2;
}

.footer {
    height: 140px;
}

.footer-nav li {
    list-style: none;
    display: inline;
    padding: 0px 10px;
    text-align: center;
}

.footer-text {
    font-size: 1em;
    text-align: center;
    margin-top: calc(40px - .5em);
}

.bps-footer-link-button {
    background-color: transparent;
    text-decoration: none;
    color: #4787C2;
    border: none;
    padding: 0px;
    font-size: 1em;
}

.bps-footer-link-button:hover, .bps-link-button:active, .bps-link-button:focus  {
    background-color: transparent;
    color: #1C304C;
    border-bottom: 1px solid #1c304c;
    box-shadow: none;
}